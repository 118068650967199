@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Catamaran", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111;
  text-align: left;
  background-color: rgb(248.8888888889, 251.0888888889, 254.5111111111);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #797979;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: rgb(82.75, 82.75, 82.75);
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Playfair Display SC", serif;
  font-weight: 600;
  line-height: 1.2;
  color: #111;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: rgb(248.8888888889, 251.0888888889, 254.5111111111);
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #111;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #111;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgb(189.2, 232.6, 223.08);
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: rgb(132.8, 213.4, 195.72);
}

.table-hover .table-primary:hover {
  background-color: rgb(170.1761904762, 226.1238095238, 213.8514285714);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: rgb(170.1761904762, 226.1238095238, 213.8514285714);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgb(213.84, 216.36, 218.6);
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgb(178.56, 183.24, 187.4);
}

.table-hover .table-secondary:hover {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: rgb(200.3075090253, 203.6560288809, 206.6324909747);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgb(189.2, 232.6, 223.08);
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: rgb(132.8, 213.4, 195.72);
}

.table-hover .table-success:hover {
  background-color: rgb(170.1761904762, 226.1238095238, 213.8514285714);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: rgb(170.1761904762, 226.1238095238, 213.8514285714);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgb(190.04, 228.96, 235.12);
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: rgb(134.36, 206.64, 218.08);
}

.table-hover .table-info:hover {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgb(255, 237.64, 185.56);
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: rgb(255, 222.76, 126.04);
}

.table-hover .table-warning:hover {
  background-color: rgb(255, 231.265, 160.06);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: rgb(255, 231.265, 160.06);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgb(245.2, 198.44, 202.92);
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: rgb(236.8, 149.96, 158.28);
}

.table-hover .table-danger:hover {
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(253.04, 253.32, 253.6);
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: rgb(251.36, 251.88, 252.4);
}

.table-hover .table-light:hover {
  background-color: rgb(238.165, 240.57, 242.975);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: rgb(238.165, 240.57, 242.975);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgb(188.36, 188.36, 188.36);
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgb(131.24, 131.24, 131.24);
}

.table-hover .table-dark:hover {
  background-color: rgb(175.61, 175.61, 175.61);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgb(175.61, 175.61, 175.61);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: rgb(69.1465517241, 77.125, 85.1034482759);
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgb(69.1465517241, 77.125, 85.1034482759);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #111;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #111;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(20, 175, 141, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-primary:hover {
  color: #fff;
  background-color: rgb(16.0769230769, 140.6730769231, 113.3423076923);
  border-color: rgb(14.7692307692, 129.2307692308, 104.1230769231);
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: rgb(16.0769230769, 140.6730769231, 113.3423076923);
  border-color: rgb(14.7692307692, 129.2307692308, 104.1230769231);
  box-shadow: 0 0 0 0.2rem rgba(55.25, 187, 158.1, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(14.7692307692, 129.2307692308, 104.1230769231);
  border-color: rgb(13.4615384615, 117.7884615385, 94.9038461538);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55.25, 187, 158.1, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: rgb(90.2703862661, 97.7929184549, 104.4796137339);
  border-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  box-shadow: 0 0 0 0.2rem rgba(130.05, 137.7, 144.5, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgb(84.3605150215, 91.3905579399, 97.6394849785);
  border-color: rgb(78.4506437768, 84.9881974249, 90.7993562232);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130.05, 137.7, 144.5, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-success:hover {
  color: #fff;
  background-color: rgb(16.0769230769, 140.6730769231, 113.3423076923);
  border-color: rgb(14.7692307692, 129.2307692308, 104.1230769231);
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: rgb(16.0769230769, 140.6730769231, 113.3423076923);
  border-color: rgb(14.7692307692, 129.2307692308, 104.1230769231);
  box-shadow: 0 0 0 0.2rem rgba(55.25, 187, 158.1, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgb(14.7692307692, 129.2307692308, 104.1230769231);
  border-color: rgb(13.4615384615, 117.7884615385, 94.9038461538);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55.25, 187, 158.1, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  box-shadow: 0 0 0 0.2rem rgba(57.8, 175.95, 194.65, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  border-color: rgb(15.9166666667, 112.1086956522, 127.3333333333);
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57.8, 175.95, 194.65, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: rgb(223.75, 167.8125, 0);
  border-color: rgb(211, 158.25, 0);
  box-shadow: 0 0 0 0.2rem rgba(221.7, 169.6, 12.1, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: rgb(211, 158.25, 0);
  border-color: rgb(198.25, 148.6875, 0);
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221.7, 169.6, 12.1, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: rgb(200.082278481, 34.667721519, 50.5158227848);
  border-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: rgb(200.082278481, 34.667721519, 50.5158227848);
  border-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
  box-shadow: 0 0 0 0.2rem rgba(225.25, 83.3, 96.9, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: rgb(189.2151898734, 32.7848101266, 47.7721518987);
  border-color: rgb(178.3481012658, 30.9018987342, 45.0284810127);
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225.25, 83.3, 96.9, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: rgb(225.6875, 229.875, 234.0625);
  border-color: rgb(218.25, 223.5, 228.75);
  box-shadow: 0 0 0 0.2rem rgba(215.75, 217.2, 218.65, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: rgb(218.25, 223.5, 228.75);
  border-color: rgb(210.8125, 217.125, 223.4375);
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215.75, 217.2, 218.65, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #111;
  border-color: #111;
}
.btn-dark:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(52.7, 52.7, 52.7, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #111;
  border-color: #111;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52.7, 52.7, 52.7, 0.5);
}

.btn-outline-primary {
  color: #14AF8D;
  border-color: #14AF8D;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 175, 141, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #14AF8D;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 175, 141, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #14AF8D;
  border-color: #14AF8D;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 175, 141, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #14AF8D;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #14AF8D;
  border-color: #14AF8D;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 175, 141, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #111;
  border-color: #111;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #111;
  border-color: #111;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #111;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #111;
  border-color: #111;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #797979;
  text-decoration: none;
}
.btn-link:hover {
  color: rgb(82.75, 82.75, 82.75);
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #14AF8D !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(12.1538461538, 106.3461538462, 85.6846153846) !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(72.5407725322, 78.5858369099, 83.9592274678) !important;
}

.text-success {
  color: #14AF8D !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(12.1538461538, 106.3461538462, 85.6846153846) !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(14.5, 102.1304347826, 116) !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(185.5, 139.125, 0) !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(167.4810126582, 29.0189873418, 42.2848101266) !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(203.375, 210.75, 218.125) !important;
}

.text-dark {
  color: #111 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #111 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

body.modal-opened {
  overscroll-behavior-y: none;
  overflow: hidden;
}

@keyframes modal-sending-request-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes modal-open-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99999;
  opacity: 0;
  transition: opacity 0.2s;
  overscroll-behavior-y: none;
}
.modal.opened {
  display: block;
  opacity: 1;
  animation: modal-open-modal 0.5s forwards;
}

.modal.sending-request::before, .modal-sending-request::before {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  margin: -40px 0 0 -40px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 5px solid #fff;
  border-right-color: transparent;
  animation: modal-sending-request-spin 2s infinite linear;
}

.modal-btn {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  line-height: 1;
}
.modal-btn.disabled {
  display: none;
}

.modal-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.modal-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.modal-top-right {
  top: 0;
  right: 0;
}

.modal-bottom-right {
  bottom: 0;
  right: 0;
}

.modal-icon-cross::before {
  content: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3C!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"%3E%3Csvg width="15px" height="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"%3E%3Cpath d="M0.314,1.829L5.985,7.5L0.314,13.171C-0.105,13.59 -0.105,14.268 0.314,14.686C0.733,15.104 1.411,15.105 1.829,14.686L7.5,9.015L13.171,14.686C13.59,15.105 14.268,15.105 14.686,14.686C15.104,14.267 15.105,13.589 14.686,13.171L9.015,7.5L14.686,1.829C15.105,1.41 15.105,0.732 14.686,0.314C14.267,-0.104 13.589,-0.105 13.171,0.314L7.5,5.985L1.829,0.314C1.41,-0.105 0.732,-0.105 0.314,0.314C-0.104,0.733 -0.105,1.411 0.314,1.829Z" style="fill:%23aaa;fill-rule:nonzero;"/%3E%3C/svg%3E');
}

.modal-icon-arrow-right::before {
  content: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3C!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"%3E%3Csvg width="15px" height="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"%3E%3Cpath d="M5.043,14.686L11.472,8.258C11.891,7.839 11.891,7.161 11.472,6.743L5.043,0.314C4.624,-0.105 3.946,-0.105 3.528,0.314C3.11,0.733 3.109,1.411 3.528,1.829L9.199,7.5L3.528,13.171C3.109,13.59 3.109,14.268 3.528,14.686C3.947,15.104 4.625,15.105 5.043,14.686Z" style="fill:%23aaa;fill-rule:nonzero;"/%3E%3C/svg%3E');
}

.modal-icon-arrow-left::before {
  content: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3C!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"%3E%3Csvg width="15px" height="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"%3E%3Cpath d="M9.957,14.686L3.528,8.258C3.109,7.839 3.109,7.161 3.528,6.743L9.957,0.314C10.376,-0.105 11.054,-0.105 11.472,0.314C11.89,0.733 11.891,1.411 11.472,1.829L5.801,7.5L11.472,13.171C11.891,13.59 11.891,14.268 11.472,14.686C11.053,15.104 10.375,15.105 9.957,14.686Z" style="fill:%23aaa;fill-rule:nonzero;"/%3E%3C/svg%3E');
}

.modal-gallery {
  background: rgba(0, 0, 0, 0.9);
}

.modal-gallery-inner {
  user-select: none;
  height: 100%;
}

.modal-gallery-btn {
  padding: 1rem;
  transition: color 0.3s, background 0.3s;
  color: #aaa;
  background: rgba(0, 0, 0, 0.3);
  font-size: 1.5rem;
  z-index: 5;
}
.modal-gallery-btn:hover {
  color: #fff;
  background: black;
}

@keyframes modal-gallery-from-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes modal-gallery-from-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes modal-gallery-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.modal-gallery-content {
  height: 100%;
  padding: 1rem;
}
.modal-gallery-content.has-navigation {
  height: calc(100% - 60px);
}

.modal-gallery-slides {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.modal-gallery-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.modal-gallery-slide.forward {
  animation-name: modal-gallery-from-right;
}
.modal-gallery-slide.backward {
  animation-name: modal-gallery-from-left;
}
.modal-gallery-slide.last.forward, .modal-gallery-slide.next.backward {
  animation-name: modal-gallery-fade-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
.modal-gallery-slide.active {
  visibility: visible;
  opacity: 1;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}

.modal-gallery-slide img, .modal-gallery-slide video, .modal-gallery-slide iframe {
  max-width: 100%;
  max-height: 100%;
}
.modal-gallery-slide iframe {
  background: #000;
}

.modal-gallery-caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  font-size: 13px;
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
  cursor: pointer;
}

.modal-gallery-nav {
  display: flex;
  height: 60px;
  padding: 2px 1rem 1rem;
  overflow: auto;
  scroll-snap-type: both mandatory;
  overscroll-behavior: none auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.modal-gallery-nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.modal-gallery-nav img {
  width: auto;
  height: 100%;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border: 2px solid transparent;
  cursor: pointer;
}
.modal-gallery-nav img:hover {
  outline: 2px solid #aaa;
}
.modal-gallery-nav img.active {
  outline: 2px solid #fff;
}
.modal-gallery-nav :first-child {
  margin-left: auto;
}
.modal-gallery-nav :last-child {
  margin-right: auto;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
  width: auto;
  padding: 0;
}

.leaflet-container img.leaflet-tile {
  /* See: https://bugs.chromium.org/p/chromium/issues/detail?id=600120 */
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline-offset: 1px;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
  font-size: 13px;
  font-size: 1.08333em;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path { /* used only in path-guessing heuristic, see L.Icon.Default */
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  display: inline !important;
  vertical-align: baseline !important;
  width: 1em;
  height: 0.6669em;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px #fff;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 24px 13px 20px;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}

.leaflet-popup-content p {
  margin: 17px 0;
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-top: -1px;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  pointer-events: auto;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px/24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/* Printing */
@media print {
  /* Prevent printers from removing background-images of controls. */
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
html {
  font-size: 16px;
}

body.no-scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

.text-stronger {
  font-weight: 500;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.mb--3 {
  margin-bottom: -1rem !important;
}

.mb--5 {
  margin-bottom: -3rem !important;
}

.sm-gutters {
  margin-left: -10px;
  margin-right: -10px;
}
.sm-gutters > * {
  padding-left: 10px;
  padding-right: 10px;
}

@font-face {
  font-family: "icomoon";
  src: url("/icomoon.ttf") format("truetype"), url("/icomoon.woff") format("woff"), url("/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icm, .service-item::after, .modal-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icm, .service-item::after, .modal-icon {
  display: inline-block;
  text-decoration: none;
  padding: 0 1px;
  vertical-align: -10%;
}

.icm-md {
  font-size: 1.35em;
}

.icm-lg {
  font-size: 1.75em;
}

.icm-2x {
  font-size: 2em;
}

.icm-3x {
  font-size: 3em;
}

.icm-activity:before {
  content: "\e900";
}

.icm-airplay:before {
  content: "\e901";
}

.icm-alert-circle:before {
  content: "\e902";
}

.icm-alert-octagon:before {
  content: "\e903";
}

.icm-alert-triangle:before {
  content: "\e904";
}

.icm-align-center:before {
  content: "\e905";
}

.icm-align-justify:before {
  content: "\e906";
}

.icm-align-left:before {
  content: "\e907";
}

.icm-align-right:before {
  content: "\e908";
}

.icm-anchor:before {
  content: "\e909";
}

.icm-aperture:before {
  content: "\e90a";
}

.icm-archive:before {
  content: "\e90b";
}

.icm-arrow-down:before {
  content: "\e90c";
}

.icm-arrow-down-circle:before {
  content: "\e90d";
}

.icm-arrow-down-left:before {
  content: "\e90e";
}

.icm-arrow-down-right:before {
  content: "\e90f";
}

.icm-arrow-left:before {
  content: "\e910";
}

.icm-arrow-left-circle:before {
  content: "\e911";
}

.icm-arrow-right:before {
  content: "\e912";
}

.icm-arrow-right-circle:before {
  content: "\e913";
}

.icm-arrow-up:before {
  content: "\e914";
}

.icm-arrow-up-circle:before {
  content: "\e915";
}

.icm-arrow-up-left:before {
  content: "\e916";
}

.icm-arrow-up-right:before {
  content: "\e917";
}

.icm-at-sign:before {
  content: "\e918";
}

.icm-award:before {
  content: "\e919";
}

.icm-bar-chart:before {
  content: "\e91a";
}

.icm-bar-chart-2:before {
  content: "\e91b";
}

.icm-battery:before {
  content: "\e91c";
}

.icm-battery-charging:before {
  content: "\e91d";
}

.icm-bell:before {
  content: "\e91e";
}

.icm-bell-off:before {
  content: "\e91f";
}

.icm-bluetooth:before {
  content: "\e920";
}

.icm-bold:before {
  content: "\e921";
}

.icm-book:before {
  content: "\e922";
}

.icm-book-open:before {
  content: "\e923";
}

.icm-bookmark:before {
  content: "\e924";
}

.icm-box:before {
  content: "\e925";
}

.icm-briefcase:before {
  content: "\e926";
}

.icm-calendar:before {
  content: "\e927";
}

.icm-camera:before {
  content: "\e928";
}

.icm-camera-off:before {
  content: "\e929";
}

.icm-cast:before {
  content: "\e92a";
}

.icm-check:before {
  content: "\e92b";
}

.icm-check-circle:before {
  content: "\e92c";
}

.icm-check-square:before {
  content: "\e92d";
}

.icm-chevron-down:before {
  content: "\e92e";
}

.icm-chevron-left:before, .modal-icon-arrow-left:before {
  content: "\e92f";
}

.icm-chevron-right:before, .modal-icon-arrow-right:before {
  content: "\e930";
}

.icm-chevron-up:before {
  content: "\e931";
}

.icm-chevrons-down:before {
  content: "\e932";
}

.icm-chevrons-left:before {
  content: "\e933";
}

.icm-chevrons-right:before {
  content: "\e934";
}

.icm-chevrons-up:before {
  content: "\e935";
}

.icm-chrome:before {
  content: "\e936";
}

.icm-circle:before {
  content: "\e937";
}

.icm-clipboard:before {
  content: "\e938";
}

.icm-clock:before {
  content: "\e939";
}

.icm-cloud:before {
  content: "\e93a";
}

.icm-cloud-drizzle:before {
  content: "\e93b";
}

.icm-cloud-lightning:before {
  content: "\e93c";
}

.icm-cloud-off:before {
  content: "\e93d";
}

.icm-cloud-rain:before {
  content: "\e93e";
}

.icm-cloud-snow:before {
  content: "\e93f";
}

.icm-code:before {
  content: "\e940";
}

.icm-codepen:before {
  content: "\e941";
}

.icm-codesandbox:before {
  content: "\e942";
}

.icm-coffee:before {
  content: "\e943";
}

.icm-columns:before {
  content: "\e944";
}

.icm-command:before {
  content: "\e945";
}

.icm-compass:before {
  content: "\e946";
}

.icm-copy:before {
  content: "\e947";
}

.icm-corner-down-left:before {
  content: "\e948";
}

.icm-corner-down-right:before {
  content: "\e949";
}

.icm-corner-left-down:before {
  content: "\e94a";
}

.icm-corner-left-up:before {
  content: "\e94b";
}

.icm-corner-right-down:before {
  content: "\e94c";
}

.icm-corner-right-up:before {
  content: "\e94d";
}

.icm-corner-up-left:before {
  content: "\e94e";
}

.icm-corner-up-right:before {
  content: "\e94f";
}

.icm-cpu:before {
  content: "\e950";
}

.icm-credit-card:before {
  content: "\e951";
}

.icm-crop:before {
  content: "\e952";
}

.icm-crosshair:before {
  content: "\e953";
}

.icm-database:before {
  content: "\e954";
}

.icm-delete:before {
  content: "\e955";
}

.icm-disc:before {
  content: "\e956";
}

.icm-dollar-sign:before {
  content: "\e957";
}

.icm-download:before {
  content: "\e958";
}

.icm-download-cloud:before {
  content: "\e959";
}

.icm-droplet:before {
  content: "\e95a";
}

.icm-edit:before {
  content: "\e95b";
}

.icm-edit-2:before {
  content: "\e95c";
}

.icm-edit-3:before {
  content: "\e95d";
}

.icm-external-link:before {
  content: "\e95e";
}

.icm-eye:before {
  content: "\e95f";
}

.icm-eye-off:before {
  content: "\e960";
}

.icm-facebook:before {
  content: "\e961";
}

.icm-fast-forward:before {
  content: "\e962";
}

.icm-feather:before {
  content: "\e963";
}

.icm-figma:before {
  content: "\e964";
}

.icm-file:before {
  content: "\e965";
}

.icm-file-minus:before {
  content: "\e966";
}

.icm-file-plus:before {
  content: "\e967";
}

.icm-file-text:before {
  content: "\e968";
}

.icm-film:before {
  content: "\e969";
}

.icm-filter:before {
  content: "\e96a";
}

.icm-flag:before {
  content: "\e96b";
}

.icm-folder:before {
  content: "\e96c";
}

.icm-folder-minus:before {
  content: "\e96d";
}

.icm-folder-plus:before {
  content: "\e96e";
}

.icm-framer:before {
  content: "\e96f";
}

.icm-frown:before {
  content: "\e970";
}

.icm-gift:before {
  content: "\e971";
}

.icm-git-branch:before {
  content: "\e972";
}

.icm-git-commit:before {
  content: "\e973";
}

.icm-git-merge:before {
  content: "\e974";
}

.icm-git-pull-request:before {
  content: "\e975";
}

.icm-github:before {
  content: "\e976";
}

.icm-gitlab:before {
  content: "\e977";
}

.icm-globe:before {
  content: "\e978";
}

.icm-grid:before {
  content: "\e979";
}

.icm-hard-drive:before {
  content: "\e97a";
}

.icm-hash:before {
  content: "\e97b";
}

.icm-headphones:before {
  content: "\e97c";
}

.icm-heart:before {
  content: "\e97d";
}

.icm-help-circle:before {
  content: "\e97e";
}

.icm-hexagon:before {
  content: "\e97f";
}

.icm-home:before {
  content: "\e980";
}

.icm-image:before {
  content: "\e981";
}

.icm-inbox:before {
  content: "\e982";
}

.icm-info:before {
  content: "\e983";
}

.icm-instagram:before {
  content: "\e984";
}

.icm-italic:before {
  content: "\e985";
}

.icm-key:before {
  content: "\e986";
}

.icm-layers:before {
  content: "\e987";
}

.icm-layout:before {
  content: "\e988";
}

.icm-life-buoy:before {
  content: "\e989";
}

.icm-link:before {
  content: "\e98a";
}

.icm-link-2:before {
  content: "\e98b";
}

.icm-linkedin:before {
  content: "\e98c";
}

.icm-list:before {
  content: "\e98d";
}

.icm-loader:before {
  content: "\e98e";
}

.icm-lock:before {
  content: "\e98f";
}

.icm-log-in:before {
  content: "\e990";
}

.icm-log-out:before {
  content: "\e991";
}

.icm-mail:before {
  content: "\e992";
}

.icm-map:before {
  content: "\e993";
}

.icm-map-pin:before {
  content: "\e994";
}

.icm-maximize:before {
  content: "\e995";
}

.icm-maximize-2:before {
  content: "\e996";
}

.icm-meh:before {
  content: "\e997";
}

.icm-menu:before {
  content: "\e998";
}

.icm-message-circle:before {
  content: "\e999";
}

.icm-message-square:before {
  content: "\e99a";
}

.icm-mic:before {
  content: "\e99b";
}

.icm-mic-off:before {
  content: "\e99c";
}

.icm-minimize:before {
  content: "\e99d";
}

.icm-minimize-2:before {
  content: "\e99e";
}

.icm-minus:before {
  content: "\e99f";
}

.icm-minus-circle:before {
  content: "\e9a0";
}

.icm-minus-square:before {
  content: "\e9a1";
}

.icm-monitor:before {
  content: "\e9a2";
}

.icm-moon:before {
  content: "\e9a3";
}

.icm-more-horizontal:before {
  content: "\e9a4";
}

.icm-more-vertical:before {
  content: "\e9a5";
}

.icm-mouse-pointer:before {
  content: "\e9a6";
}

.icm-move:before {
  content: "\e9a7";
}

.icm-music:before {
  content: "\e9a8";
}

.icm-navigation:before {
  content: "\e9a9";
}

.icm-navigation-2:before {
  content: "\e9aa";
}

.icm-octagon:before {
  content: "\e9ab";
}

.icm-package:before {
  content: "\e9ac";
}

.icm-paperclip:before {
  content: "\e9ad";
}

.icm-pause:before {
  content: "\e9ae";
}

.icm-pause-circle:before {
  content: "\e9af";
}

.icm-pen-tool:before {
  content: "\e9b0";
}

.icm-percent:before {
  content: "\e9b1";
}

.icm-phone:before {
  content: "\e9b2";
}

.icm-phone-call:before {
  content: "\e9b3";
}

.icm-phone-forwarded:before {
  content: "\e9b4";
}

.icm-phone-incoming:before {
  content: "\e9b5";
}

.icm-phone-missed:before {
  content: "\e9b6";
}

.icm-phone-off:before {
  content: "\e9b7";
}

.icm-phone-outgoing:before {
  content: "\e9b8";
}

.icm-pie-chart:before {
  content: "\e9b9";
}

.icm-play:before {
  content: "\e9ba";
}

.icm-play-circle:before {
  content: "\e9bb";
}

.icm-plus:before {
  content: "\e9bc";
}

.icm-plus-circle:before {
  content: "\e9bd";
}

.icm-plus-square:before {
  content: "\e9be";
}

.icm-pocket:before {
  content: "\e9bf";
}

.icm-power:before {
  content: "\e9c0";
}

.icm-printer:before {
  content: "\e9c1";
}

.icm-radio:before {
  content: "\e9c2";
}

.icm-refresh-ccw:before {
  content: "\e9c3";
}

.icm-refresh-cw:before {
  content: "\e9c4";
}

.icm-repeat:before {
  content: "\e9c5";
}

.icm-rewind:before {
  content: "\e9c6";
}

.icm-rotate-ccw:before {
  content: "\e9c7";
}

.icm-rotate-cw:before {
  content: "\e9c8";
}

.icm-rss:before {
  content: "\e9c9";
}

.icm-save:before {
  content: "\e9ca";
}

.icm-scissors:before {
  content: "\e9cb";
}

.icm-search:before {
  content: "\e9cc";
}

.icm-send:before {
  content: "\e9cd";
}

.icm-server:before {
  content: "\e9ce";
}

.icm-settings:before {
  content: "\e9cf";
}

.icm-share:before {
  content: "\e9d0";
}

.icm-share-2:before {
  content: "\e9d1";
}

.icm-shield:before {
  content: "\e9d2";
}

.icm-shield-off:before {
  content: "\e9d3";
}

.icm-shopping-bag:before {
  content: "\e9d4";
}

.icm-shopping-cart:before {
  content: "\e9d5";
}

.icm-shuffle:before {
  content: "\e9d6";
}

.icm-sidebar:before {
  content: "\e9d7";
}

.icm-skip-back:before {
  content: "\e9d8";
}

.icm-skip-forward:before {
  content: "\e9d9";
}

.icm-slack:before {
  content: "\e9da";
}

.icm-slash:before {
  content: "\e9db";
}

.icm-sliders:before {
  content: "\e9dc";
}

.icm-smartphone:before {
  content: "\e9dd";
}

.icm-smile:before {
  content: "\e9de";
}

.icm-speaker:before {
  content: "\e9df";
}

.icm-square:before {
  content: "\e9e0";
}

.icm-star:before {
  content: "\e9e1";
}

.icm-stop-circle:before {
  content: "\e9e2";
}

.icm-sun:before {
  content: "\e9e3";
}

.icm-sunrise:before {
  content: "\e9e4";
}

.icm-sunset:before {
  content: "\e9e5";
}

.icm-tablet:before {
  content: "\e9e6";
}

.icm-tag:before {
  content: "\e9e7";
}

.icm-target:before {
  content: "\e9e8";
}

.icm-terminal:before {
  content: "\e9e9";
}

.icm-thermometer:before {
  content: "\e9ea";
}

.icm-thumbs-down:before {
  content: "\e9eb";
}

.icm-thumbs-up:before {
  content: "\e9ec";
}

.icm-toggle-left:before {
  content: "\e9ed";
}

.icm-toggle-right:before {
  content: "\e9ee";
}

.icm-tool:before {
  content: "\e9ef";
}

.icm-trash:before {
  content: "\e9f0";
}

.icm-trash-2:before {
  content: "\e9f1";
}

.icm-trello:before {
  content: "\e9f2";
}

.icm-trending-down:before {
  content: "\e9f3";
}

.icm-trending-up:before {
  content: "\e9f4";
}

.icm-triangle:before {
  content: "\e9f5";
}

.icm-truck:before {
  content: "\e9f6";
}

.icm-tv:before {
  content: "\e9f7";
}

.icm-twitch:before {
  content: "\e9f8";
}

.icm-twitter:before {
  content: "\e9f9";
}

.icm-type:before {
  content: "\e9fa";
}

.icm-umbrella:before {
  content: "\e9fb";
}

.icm-underline:before {
  content: "\e9fc";
}

.icm-unlock:before {
  content: "\e9fd";
}

.icm-upload:before {
  content: "\e9fe";
}

.icm-upload-cloud:before {
  content: "\e9ff";
}

.icm-user:before {
  content: "\ea00";
}

.icm-user-check:before {
  content: "\ea01";
}

.icm-user-minus:before {
  content: "\ea02";
}

.icm-user-plus:before {
  content: "\ea03";
}

.icm-user-x:before {
  content: "\ea04";
}

.icm-users:before {
  content: "\ea05";
}

.icm-video:before {
  content: "\ea06";
}

.icm-video-off:before {
  content: "\ea07";
}

.icm-voicemail:before {
  content: "\ea08";
}

.icm-volume:before {
  content: "\ea09";
}

.icm-volume-1:before {
  content: "\ea0a";
}

.icm-volume-2:before {
  content: "\ea0b";
}

.icm-volume-x:before {
  content: "\ea0c";
}

.icm-watch:before {
  content: "\ea0d";
}

.icm-wifi:before {
  content: "\ea0e";
}

.icm-wifi-off:before {
  content: "\ea0f";
}

.icm-wind:before {
  content: "\ea10";
}

.icm-x:before, .modal-icon-cross:before {
  content: "\ea11";
}

.icm-x-circle:before {
  content: "\ea12";
}

.icm-x-octagon:before {
  content: "\ea13";
}

.icm-x-square:before {
  content: "\ea14";
}

.icm-youtube:before {
  content: "\ea15";
}

.icm-zap:before {
  content: "\ea16";
}

.icm-zap-off:before {
  content: "\ea17";
}

.icm-zoom-in:before {
  content: "\ea18";
}

.icm-zoom-out:before {
  content: "\ea19";
}

.page-header {
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  box-shadow: 0 0 10px -2px rgba(6, 51, 120, 0.4);
}

.page-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-navigation > ul {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}
.page-navigation > ul li + li {
  border-left: 1px solid rgb(222.0363636364, 214.9090909091, 204.6636363636);
  margin-left: 1rem;
  padding-left: 1rem;
}
.page-navigation > ul a {
  display: inline-block;
  color: #111;
  text-transform: uppercase;
}
.page-navigation > ul a:hover, .page-navigation > ul a:focus, .page-navigation > ul a:active {
  text-decoration: none;
}
.page-navigation > ul a::before, .page-navigation > ul a::after {
  content: "";
  display: block;
  height: 1px;
  transition: background 0.4s, transform 0.4s, opacity 0.4s;
  opacity: 0;
  transform: scaleX(0.5);
}
.page-navigation > ul a::after {
  background: #14AF8D;
}
.page-navigation > ul a.page-navigation-primary {
  color: #2B7CBA;
}
.page-navigation > ul a.page-navigation-secondary {
  color: #3D90B8;
}
.page-navigation > ul a.page-navigation-ternary {
  color: #14AF8D;
}
.page-navigation > ul li.active a, .page-navigation > ul a:hover, .page-navigation > ul a:focus, .page-navigation > ul a:active {
  background: transparent;
}
.page-navigation > ul li.active a::after, .page-navigation > ul a:hover::after, .page-navigation > ul a:focus::after, .page-navigation > ul a:active::after {
  transform: scaleX(1);
  opacity: 1;
}

.page-navigation-menu {
  position: relative;
}

.page-navigation-items {
  display: none;
  position: absolute;
  z-index: 999;
  right: 0;
  padding: 1rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
  margin-top: 5px;
}
.page-navigation-items ul {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.page-navigation-items li {
  margin: 0;
  padding: 0;
}
.page-navigation-items li + li {
  border-top: 1px solid rgb(222.0363636364, 214.9090909091, 204.6636363636);
}
.page-navigation-items a {
  display: block;
  white-space: nowrap;
  text-transform: uppercase;
  color: #111;
  padding: 0.5rem 1rem;
  transition: color 0.3s;
}
.page-navigation-items a:hover, .page-navigation-items a:focus, .page-navigation-items a:active {
  text-decoration: none;
  color: #14AF8D;
}
.page-navigation-items li.active a {
  color: #063378;
}

.page-hamburger {
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: 700;
  text-align: center;
  padding: 0.3rem;
  transition: background 0.3s;
  cursor: pointer;
  border: 1px solid #14AF8D;
  color: #111;
  user-select: none;
}
.page-hamburger:hover, .page-hamburger:active, .page-hamburger:focus {
  color: #fff;
  background: #14AF8D;
  border-color: #14AF8D;
}
.page-hamburger:hover .page-hamburger-icon-inner:before, .page-hamburger:hover .page-hamburger-icon-inner:after, .page-hamburger:active .page-hamburger-icon-inner:before, .page-hamburger:active .page-hamburger-icon-inner:after, .page-hamburger:focus .page-hamburger-icon-inner:before, .page-hamburger:focus .page-hamburger-icon-inner:after {
  border-color: #fff;
}

.page-hamburger-icon {
  width: 20px;
  height: 20px;
  margin: 0 auto 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-hamburger-icon-inner {
  display: inline-block;
  width: 20px;
  height: 14px;
  overflow: hidden;
  transition: 1s linear;
  position: relative;
}
.page-hamburger-icon-inner:before, .page-hamburger-icon-inner:after {
  content: "";
  display: block;
  width: 100%;
  height: 7px;
  transition: 0.5s linear;
  position: absolute;
  top: 50%;
  left: 50%;
}
.page-hamburger-icon-inner:before {
  transform: translate(-50%, -100%);
  border-top: 2px solid #14AF8D;
  border-bottom: 1px solid #14AF8D;
}
.page-hamburger-icon-inner:after {
  transform: translate(-50%, 0);
  border-top: 1px solid #14AF8D;
  border-bottom: 2px solid #14AF8D;
}

.page-hamburger.active {
  background: #14AF8D;
  color: #fff;
}
.page-hamburger.active .page-hamburger-icon-inner:before, .page-hamburger.active .page-hamburger-icon-inner:after {
  height: 0;
  border-color: #fff;
}
.page-hamburger.active .page-hamburger-icon-inner:before {
  border-bottom-width: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.page-hamburger.active .page-hamburger-icon-inner:after {
  border-top-width: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.page-hamburger.active span {
  display: none;
}

@media (min-width: 992px) {
  .page-navigation-items li.d-lg-none + li {
    border-top: 0;
  }
}
.branch-card {
  display: flex;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px -2px rgba(6, 51, 120, 0.4);
  padding: 1.5rem;
  line-height: 1.2;
  transition: background 0.3s, box-shadow 0.3s, transform 0.7s;
  color: #111;
  transform: scale(1);
  background: #fff;
  align-items: center;
}
.branch-card:hover {
  text-decoration: none;
  box-shadow: 0 0 15px -2px rgba(20, 175, 141, 0.8);
  background: #E4FEF8;
  color: #111;
}
.branch-card:hover .branch-card-title {
  color: #063378;
}

.branch-card-description {
  flex: 1 1 100%;
}

.branch-card-image {
  width: 60px;
  flex: 0 0 60px;
  margin-right: 1rem;
}
.branch-card-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.branch-card-title {
  color: #063378;
  font-size: 1.25rem;
  font-weight: 700;
  transition: color 0.3s;
  margin-bottom: 0.25rem;
}

.branch-flat-card {
  padding: 1.5rem 1rem;
  background: #E6EFFD;
  border-radius: 0.25rem;
  height: 100%;
}
.branch-flat-card a {
  color: #063378;
}
.branch-flat-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.branch-flat-card-name {
  display: block;
  color: #063378;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.branch-flat-card-contact {
  margin-top: 0.5rem;
}

@media (min-width: 576px) {
  .branch-card {
    display: block;
    text-align: center;
    height: 100%;
  }
  .branch-card-image {
    width: 100%;
    height: 100px;
    margin-bottom: 1rem;
  }
}
.container-fluid-xl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
}

.container-fixed-md {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-fixed-md {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-fixed-md {
    max-width: 720px;
  }
}

.container-fixed-lg {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-fixed-lg {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-fixed-lg {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-fixed-lg {
    max-width: 960px;
  }
}

@media (min-width: 992px) {
  .large-gutters-lg {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .large-gutters-lg > .col,
  .large-gutters-lg > [class*=col-] {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
.news-card {
  display: flex;
  flex-direction: column;
  background: #063378;
  color: #fff;
  border-radius: 0.25rem;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: transform 0.7s;
}
.news-card:hover {
  text-decoration: none;
  transform: scale(1.07);
  color: #fff;
}
.news-card:hover .news-card-footer {
  transform: translateY(-100%);
}

.news-card-title {
  font-family: "Catamaran", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: #fff;
  padding-top: 2rem;
  margin-bottom: 0.5rem;
}

.news-card-date {
  margin-bottom: 1rem;
}

.news-card-content {
  margin-bottom: 1rem;
}

.news-card-title, .news-card-content, .news-card-date {
  padding-left: 1rem;
  padding-right: 1rem;
}

.news-card-image {
  height: 250px;
  position: relative;
  overflow: hidden;
  margin-top: auto;
}
.news-card-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 1000%;
  min-height: 1000%;
  transform: translate(-50%, -50%) scale(0.10001);
}

.news-card-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  background: #14AF8D;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  transition: transform 0.7s;
}

.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section-h1 {
  text-align: center;
  line-height: 1.2;
}
.section-h1::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #14AF8D;
  margin: 1.5rem auto 0;
}
.section-h1 h1, .section-h1 h2 {
  font-size: 2.0625rem;
  color: #063378;
}
.section-h1 h1 span, .section-h1 h2 span {
  color: #14AF8D;
}
.section-h1 .suptitle {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  color: #797979;
}
.section-h1 .subtitle {
  letter-spacing: 1px;
  font-style: italic;
  color: #797979;
  margin-top: 0.75rem;
  font-size: 0.9375rem;
}
.section-h1 + .section-body {
  margin-top: 3rem;
}

.section-h2 {
  text-align: center;
  line-height: 1.2;
}
.section-h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #14AF8D;
  margin: 1rem auto 0;
}
.section-h2 h1, .section-h2 h2 {
  font-size: 1.875rem;
  color: #063378;
  letter-spacing: 1px;
  margin: 0;
}
.section-h2 h1 span, .section-h2 h2 span {
  color: #14AF8D;
}
.section-h2 .suptitle {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  color: #797979;
}
.section-h2 .subtitle {
  letter-spacing: 1px;
  font-style: italic;
  color: #797979;
  margin-top: 0.75rem;
  font-size: 0.875rem;
}
.section-h2 + .section-body {
  margin-top: 3rem;
}

.section-h3::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #14AF8D;
  margin-top: 0.5rem;
}
.section-h3 h2, .section-h3 h3 {
  font-size: 1.5rem;
  color: #063378;
}
.section-h3 + .section-body {
  margin-top: 1.5rem;
}
.section-h3.text-center::after {
  margin-left: auto;
  margin-right: auto;
}

.section-h4 h2, .section-h4 h3 {
  font-size: 1.25rem;
  color: #14AF8D;
}
.section-h4 + .section-body {
  margin-top: 1rem;
}

.section-blockquote {
  padding-left: 3rem;
}

.section-footer {
  margin-top: 3rem;
}

.section-light {
  background: #E6EFFD;
}

.user-content::after {
  display: block;
  content: "";
  clear: both;
}
.user-content .perex {
  color: #063378;
  font-size: 1.125rem;
}
.user-content p {
  text-align: justify;
}
.user-content figure {
  display: block;
  text-align: center;
  margin-bottom: 2rem;
}
.user-content figure img {
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px #14AF8D;
}
.user-content img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .user-content figure.float-right {
    max-width: 45%;
    float: right;
    margin-left: 2.5rem;
  }
}
.document-card {
  display: flex;
  align-items: center;
  color: #111;
  background: #fff;
  border-radius: 0.25rem;
  padding: 1rem;
  transition: color 0.3s, background 0.3s;
  box-shadow: 0 0 10px -2px rgba(6, 51, 120, 0.4);
  line-height: 1.2;
}
.document-card:hover {
  text-decoration: none;
  background: #E4FEF8;
  color: #111;
}
.document-card:hover .document-card-file .filename {
  opacity: 1;
}

.document-card-icon {
  width: 35px;
  flex: 0 0 35px;
  margin-right: 1rem;
}

.document-card-title {
  color: #063378;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.document-card-content {
  flex: 1 1 100%;
}

.document-card-file {
  color: #6c757d;
  text-align: right;
}

@media (min-width: 576px) {
  .document-card {
    padding: 1rem 1.5rem;
  }
  .document-card-icon {
    width: 50px;
    flex: 0 0 50px;
    margin-right: 1.5rem;
  }
}
.table.w-auto {
  width: auto;
}
.table.table-sm td, .table.table-sm th {
  padding-left: 1rem;
  padding-right: 1rem;
}
.table tbody td, .table tbody th, .table thead td, .table thead th {
  border-color: #ddd;
}
.table tr:first-child th, .table tr:first-child td {
  border-top: 0;
}
.table .fit-width {
  width: 1px;
  white-space: nowrap;
}

@media (max-width: 575.98px) {
  table.table.table-opening-hours {
    display: block;
  }
  table.table.table-opening-hours tr {
    display: flex;
    flex-wrap: wrap;
  }
  table.table.table-opening-hours tr + tr {
    border-top: 1px solid #dee2e6;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
  }
  table.table.table-opening-hours th, table.table.table-opening-hours td {
    display: block;
    border: 0;
    padding: 0 1rem;
    width: auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  table.table.table-opening-hours th, table.table.table-opening-hours td:last-child {
    flex: 0 0 100%;
  }
}
.person-card {
  text-align: center;
  padding: 1.5rem 1rem;
  background: #E6EFFD;
  border-radius: 0.25rem;
  height: 100%;
}
.person-card a {
  color: #063378;
}

.person-card-image {
  margin-bottom: 1rem;
}
.person-card-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 250px;
  display: inline-block;
  border: 1px solid #E6EFFD;
  box-shadow: 0 0 0 1px #14AF8D;
}

.person-card-name {
  color: #063378;
  font-weight: 700;
  font-size: 1.25rem;
}

.person-card-branches {
  margin-top: 0.5rem;
  text-align: left;
  display: flex;
  justify-content: center;
}
.person-card-branches ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.service-item {
  display: block;
  color: #fff;
  background: #063378;
  border-radius: 0.25rem;
  padding: 0.5rem 4rem 0.5rem 1rem;
  transition: background 0.3s;
  position: relative;
  overflow: hidden;
}
.service-item::after {
  content: "\e930";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
  transform: translateX(101%);
  transition: transform 0.3s;
}
.service-item:hover {
  text-decoration: none;
  color: #fff;
  background: #14AF8D;
}
.service-item:hover::after {
  transform: translateX(0);
}
.service-item + .service-item {
  margin-top: 0.5rem;
}

.page-breadcrumb {
  font-size: 0.8125rem;
  padding: 0.5rem 1rem;
  display: inline-flex;
  flex-wrap: wrap;
  background: #E6EFFD;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-breadcrumb ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.page-breadcrumb li {
  display: flex;
  align-items: baseline;
}
.page-breadcrumb li::before {
  content: "\\";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  color: #96979A;
}
.page-breadcrumb a {
  display: inline-block;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  color: #063378;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-breadcrumb a:hover, .page-breadcrumb a:focus, .page-breadcrumb a:active {
  color: #063378;
  text-decoration: underline;
}

.page-footer {
  color: #fff;
  background: #063378;
  padding: 3rem 0;
}
.page-footer a {
  color: #fff;
}

.section + .page-footer {
  margin-top: 3rem;
}

.page-footer-header {
  font-family: "Playfair Display SC", serif;
  line-height: 1.2;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.page-footer-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-copyright {
  background: rgb(3.5714285714, 30.3571428571, 71.4285714286);
  text-align: center;
  font-size: 0.8125rem;
  padding: 0.5rem 0;
  color: #bbb;
}
.page-copyright p {
  margin: 0;
}
.page-copyright a {
  color: #14AF8D;
}

#map {
  height: 400px;
  border-radius: 0.25rem;
  overflow: hidden;
}

#map-mini {
  height: 300px;
  border-radius: 0.25rem;
  overflow: hidden;
}

.map-trip-pin {
  background: #fff;
  font-family: "Catamaran", sans-serif;
  font-size: 0.8125rem;
  line-height: 1;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-left: 2px solid #dc3545;
  white-space: nowrap;
  opacity: 0.8;
  cursor: default;
}
.map-trip-pin:hover {
  opacity: 1;
  z-index: 9999;
}
.map-trip-pin .row {
  align-items: center;
}
.map-trip-pin .row :first-child {
  margin-right: 0.25rem;
}
.map-trip-pin .date {
  font-weight: 700;
}
.map-trip-pin small, .map-trip-pin .title {
  font-weight: 400;
  font-size: 0.75rem;
  color: #007bff;
}
.map-trip-pin img.map-trip-pin-height-25 {
  height: 20px !important;
}
.map-trip-pin img.map-trip-pin-height-20 {
  height: 15px !important;
}

.smap .card {
  font-family: "Catamaran", sans-serif;
  line-height: 1.5;
}
.smap .card .card-header {
  font-weight: 700;
  color: #063378;
}

.medium-card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid #14AF8D;
  padding: 1.5rem;
  background: #fff;
}

.medium-card-image {
  height: 200px;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
}
.medium-card-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.1001);
  width: auto;
  height: auto;
  min-width: 1000%;
  min-height: 1000%;
}

.medium-card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #063378;
}

.medium-card-date, .medium-card-topic {
  color: #797979;
}

.medium-card-content {
  margin-top: 0.5rem;
}

.medium-card-link {
  text-align: center;
  margin-top: 0.5rem;
}

.prices-card {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem;
}
.prices-card + .prices-card {
  margin-top: 1.5rem;
}

.prices-card-header {
  margin-bottom: 1rem;
}
.prices-card-header h2 {
  font-size: 1.5rem;
  margin-bottom: 0;
  text-align: center;
  color: #063378;
}

.prices-card-body .table {
  margin-bottom: 0;
}

.homepage-intro-item {
  position: relative;
}

.homepage-intro-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.homepage-intro-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  height: auto;
  min-width: 1000%;
  min-height: 1000%;
  transform: translate(-50%, -50%) scale(0.10001);
}

.homepage-intro-content {
  position: relative;
  min-height: 450px;
}

.homepage-intro-body {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  backdrop-filter: blur(3px);
  width: 90%;
  line-height: 1.2;
  background: rgba(6, 51, 120, 0.85);
  border-radius: 0.25rem;
  text-align: center;
  color: #fff;
  padding: 1rem;
}
.homepage-intro-body strong {
  font-weight: normal;
  color: #14AF8D;
}
.homepage-intro-body .title {
  font-family: "Playfair Display SC", serif;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
.homepage-intro-body .subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media (min-width: 768px) {
  .homepage-intro-body {
    width: 70%;
    padding: 1rem 3rem;
  }
  .homepage-intro-body .title {
    font-size: 1.875rem;
  }
  .homepage-intro-body .subtitle {
    font-size: 1.125rem;
  }
}
@media (min-width: 992px) {
  .homepage-intro-body {
    width: auto;
  }
}
